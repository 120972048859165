
import { computed, defineComponent, onMounted, ref, unref } from "vue";
import FsLightbox from "fslightbox-vue/v3";
import { useRoute, useRouter } from "vue-router";
import { ApiLogisticsServiceProvider, ApiOrderRequest } from "@/core/api";
import moment from "moment";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import ItemModal from "./ItemModal.vue";
import {
  orderRequestInformationData,
  orderRequestItemData,
  orderRequestItemSubData,
  OrderRequestItemTable,
} from "@/core/directive/interface/orderRequest";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { OrderRequestProgress } from "@/core/directive/type/orderRequest";

export default defineComponent({
  name: "merchant-order-order-request-information",
  components: { ItemModal, FsLightbox },
  setup() {
    const { t } = useI18n();

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const isAllChecked = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const isCopyData = ref<boolean>(false);
    const copysubData = ref(Object.assign({}, orderRequestItemSubData));
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const formData = ref(Object.assign({}, orderRequestInformationData));

    const selectedIds = ref<any>([]);

    const itemId = ref(0);
    const itemData = ref(Object.assign({}, orderRequestItemData));
    const itemIndex = ref(0);
    const itemSubData = ref(Object.assign({}, orderRequestItemSubData));
    const itemSubIndex = ref(0);

    const disabledConfirmed = ref(false);
    const disabledShipped = ref(false);

    const rules = ref({
      // "items.confirmed_qty": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "items.relation_shipment.merchant_order_number": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      "relation_items.relation_shipment.confirmation_date": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // "items.relation_shipment.merchant_invoice_number": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "items.relation_shipment.shipping_date": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "items.relation_shipment.service_provider": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "items.relation_shipment.tracking_no": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      "relation_items.relation_shipment.shipping_qty": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      serviceProvider: new Map([]),
      service_provider: [] as TaggingItem[],
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    let defaultTable = unref<Array<OrderRequestItemTable>>([]);

    const toggleAllSelect = () => {
      if (formData.value.relation_items.length === 0 || loading.value) return;
      isAllChecked.value = !isAllChecked.value;
      formData.value.relation_items.forEach((item) => {
        if (
          (formData.value.order_progress === OrderRequestProgress.Requested &&
            item.confirmed_flag === 1) ||
          (formData.value.order_progress === OrderRequestProgress.Confirmed &&
            item.shipped_flag === 1)
        ) {
          //
        } else {
          item.isChecked = isAllChecked.value;
        }
      });
      getSelectedRows();
    };

    const toggleRowSelect = (item, index) => {
      if (
        (formData.value.order_progress === OrderRequestProgress.Requested &&
          item.confirmed_flag === 1) ||
        (formData.value.order_progress === OrderRequestProgress.Confirmed &&
          item.shipped_flag === 1)
      )
        return;
      item.isChecked = !item.isChecked;
      if (
        item.isChecked &&
        item.request_qty - item.confirmed_qty > 0 &&
        copysubData.value.request_items_id !== item.id &&
        isCopyData.value
      ) {
        const defaultData = Object.assign(
          {},
          orderRequestItemSubData,
          copysubData.value,
          {
            request_items_id: item.id,
            shipping_qty: item.request_qty - item.confirmed_qty,
          }
        );
        item.relation_shipment.push(defaultData);
        itemData.value = item;
        itemIndex.value = index;
        itemSubIndex.value = -1;
        itemId.value = 0;
        item.confirmed_qty = itemQtyValue.value;
      }
      getSelectedRows();
    };

    const clearSelect = () => {
      isAllChecked.value = false;
      selectedIds.value = [];
      getSelectedRows();
    };

    const getSelectedRows = async () => {
      let rows: number[] = [],
        checkbox: OrderRequestItemTable[] = [];
      formData.value.relation_items.forEach((item) => {
        if (item.isChecked) {
          rows.push(item.id);
        }
        if (
          (formData.value.order_progress === OrderRequestProgress.Requested &&
            item.confirmed_flag === 1) ||
          (formData.value.order_progress === OrderRequestProgress.Confirmed &&
            item.shipped_flag === 1)
        ) {
          //
        } else {
          checkbox.push(item);
        }
      });
      selectedIds.value.splice(0, selectedIds.value.length, ...rows);
      isAllChecked.value = checkbox.length == selectedIds.value.length;
      if (!isCopyData.value) {
        disabledConfirmed.value = isDisabledConfirmed();
        disabledShipped.value = isDisabledShipped();
      }
      return selectedIds.value;
    };

    const getShowInfo = async () => {
      const { data } = await ApiOrderRequest.getOrderRequestInfo({
        id: route.params.id,
      });
      if (data.code == 0) {
        data.data.relation_items.forEach((item) => {
          item.isChecked = false;
        });
        formData.value = data.data;
        defaultTable = data.data.relation_items;
        disabledConfirmed.value = isDisabledConfirmed();
        disabledShipped.value = isDisabledShipped();
        isCopyData.value = false;
        clearSelect();
      }
    };

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        options.value.service_provider = data.data;
        data.data.map((item) => {
          options.value.serviceProvider.set(item.value, item.label);
        });
      }
    };

    const canceledOrderRequest = () => {
      Swal.fire({
        text: t("orderRequest.canceledTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiOrderRequest.canceledOrderRequest({
            id: route.params.id,
          });
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFormInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const confirmedOrderRequest = () => {
      Swal.fire({
        text: t("orderRequest.confirmedTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiOrderRequest.confirmedOrderRequest({
            id: route.params.id,
            item_ids: selectedIds.value,
          });
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFormInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const shippedOrderRequest = () => {
      if (loading.value) return;
      Swal.fire({
        text: t("orderRequest.shippedTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiOrderRequest.shippedOrderRequest({
            id: route.params.id,
            item_ids: selectedIds.value,
          });
          loading.value = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFormInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const isDisabledConfirmed = () => {
      let flag = false;
      if (selectedIds.value.length === 0) {
        flag = true;
      } else {
        flag = defaultTable.find(
          (item) =>
            selectedIds.value.indexOf(item.id) > -1 &&
            item.relation_shipment.length === 0
        )
          ? true
          : false;
        if (!flag) {
          let isFlag = false;
          defaultTable.map((item) => {
            if (!isFlag) {
              isFlag = item.relation_shipment.find(
                (sub) =>
                  selectedIds.value.indexOf(item.id) > -1 &&
                  (sub.shipping_qty === 0 ||
                    sub.confirmation_date === "" ||
                    sub.confirmation_date === null)
              )
                ? true
                : false;
            }
          });
          flag = isFlag;
        }
      }
      return flag;
    };

    const isDisabledShipped = () => {
      let flag = false;
      if (selectedIds.value.length === 0) {
        flag = true;
      } else {
        flag = defaultTable.find(
          (item) =>
            selectedIds.value.indexOf(item.id) > -1 &&
            item.relation_shipment.length === 0
        )
          ? true
          : false;
        if (!flag) {
          let isFlag = false;
          defaultTable.map((item) => {
            if (!isFlag) {
              isFlag = item.relation_shipment.find(
                (sub) =>
                  selectedIds.value.indexOf(item.id) > -1 &&
                  (sub.service_provider === "" ||
                    sub.tracking_no === "" ||
                    sub.shipping_date === "" ||
                    sub.shipping_date === null)
              )
                ? true
                : false;
            }
          });
          flag = isFlag;
        }
      }
      return flag;
    };

    const isDisabledItem = computed(() => {
      return (item) => {
        let subTotal = 0;
        item.relation_shipment.map((sub) => {
          subTotal += sub.shipping_qty;
        });
        if (subTotal >= item.request_qty) {
          return true;
        }
        return false;
      };
    });

    const itemQtyMin = computed(() => {
      return (item) => {
        let subTotal = 0;
        item.relation_shipment.map((sub) => {
          subTotal += sub.shipping_qty;
        });
        if (item.request_qty > 0) {
          if (subTotal > 0) {
            return subTotal;
          } else {
            return 1;
          }
        } else {
          return item.request_qty;
        }
      };
    });

    const subQtyMax = computed(() => {
      return (item, rowIndex) => {
        let subTotal = 0;
        item.relation_shipment.map((sub, subIndex) => {
          if (subIndex != rowIndex) {
            subTotal += sub.shipping_qty;
          }
        });
        return item.request_qty - subTotal;
      };
    });

    const itemQtyValue = computed(() => {
      let subTotal = 0;
      formData.value.relation_items[itemIndex.value].relation_shipment.map(
        (sub) => {
          subTotal += sub.shipping_qty;
        }
      );
      return subTotal;
    });

    const addSubItem = (item, index) => {
      itemData.value = item;
      itemIndex.value = index;
      itemSubIndex.value = -1;
      // itemId.value = item.id;
      const defaultData = Object.assign({}, orderRequestItemSubData, {
        request_items_id: item.id,
        confirmation_date: moment().add(12, "hours").toISOString(),
        shipping_qty: item.request_qty - item.confirmed_qty,
      });
      itemSubData.value = Object.assign({}, defaultData);
      item.relation_shipment.push(defaultData);
      item.confirmed_qty = itemQtyValue.value;
    };

    const editSubItem = (item, index, sub, subIndex) => {
      itemSubData.value = Object.assign({}, orderRequestItemSubData, sub);
      itemData.value = item;
      itemIndex.value = index;
      itemSubIndex.value = subIndex;
      itemId.value = item.id;
    };

    const copySubItem = (item, index, sub, subIndex) => {
      itemSubData.value = Object.assign({}, orderRequestItemSubData, sub);
      itemData.value = item;
      itemIndex.value = index;
      itemSubIndex.value = subIndex;
      itemId.value = item.id;
      isCopyData.value = true;
      copysubData.value = Object.assign({}, orderRequestItemSubData, sub, {
        id: 0,
      });
    };

    const shippingQtyChange = (e, item, index, sub, subIndex) => {
      itemData.value = item;
      itemIndex.value = index;
      const defaultData = Object.assign({}, orderRequestItemSubData);
      itemSubData.value = Object.assign(defaultData, sub);
      itemSubIndex.value = subIndex;
      itemId.value = item.id;
      item.confirmed_qty = itemQtyValue.value;
    };

    const deleteSubItem = (item, index, sub, subIndex) => {
      Swal.fire({
        text: t("orderRequest.deleteTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (sub.id === 0) {
            deleteSubItemData(item, index, sub, subIndex);
          } else {
            loading.value = true;
            const { data } = await ApiOrderRequest.deleteOrderRequestItem({
              merchant_request_id: item.merchant_request_id,
              request_item_id: item.id,
              id: sub.id,
            });
            loading.value = false;
            if (data.code === 0) {
              deleteSubItemData(item, index, sub, subIndex);
            } else {
              showServerErrorMsg(data);
            }
          }
        }
      });
    };

    const deleteSubItemData = (item, index, sub, subIndex) => {
      item.relation_shipment.splice(subIndex, 1);
      itemData.value = item;
      itemIndex.value = index;
      const defaultData = Object.assign({}, orderRequestItemSubData);
      itemSubData.value = Object.assign(defaultData, {
        request_items_id: item.id,
      });
      itemSubIndex.value = subIndex;
      itemId.value = 0;
      item.confirmed_qty = itemQtyValue.value;
    };

    const handleReset = () => {
      itemId.value = 0;
    };

    const updateList = (e) => {
      if (itemSubIndex.value === -1) {
        formData.value.relation_items[itemIndex.value].relation_shipment.push(
          e
        );
      } else {
        formData.value.relation_items[itemIndex.value].relation_shipment[
          itemSubIndex.value
        ] = e;
      }
      formData.value.relation_items[itemIndex.value].confirmed_qty =
        itemQtyValue.value;
    };

    const previewImages = (previewImages: string[], imageIndex: number) => {
      options.value.sources = previewImages;
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const dateChange = (e, index, rowIndex, type) => {
      if (e) {
        formData.value.relation_items[index].relation_shipment[rowIndex][type] =
          moment(e, "YYYY-MM-DD").add(12, "hours").toISOString();
      }
    };

    const backToList = () => {
      commonBackToList(router, "/merchant-order/order-request");
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiOrderRequest.updateOrderRequest({
            id: route.params.id,
            relation_items: formData.value.relation_items,
          })
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getFormInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };
    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFormInfo();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      OrderRequestProgress,
      loading,
      formRef,
      submitButton,
      formData,
      rules,
      options,
      disabledConfirmed,
      disabledShipped,
      isAllChecked,
      toggleAllSelect,
      toggleRowSelect,
      isDisabledConfirmed,
      isDisabledShipped,
      isDisabledItem,
      itemQtyMin,
      subQtyMax,
      itemId,
      itemData,
      itemIndex,
      itemSubData,
      itemSubIndex,
      canceledOrderRequest,
      confirmedOrderRequest,
      shippedOrderRequest,
      handleReset,
      addSubItem,
      editSubItem,
      copySubItem,
      shippingQtyChange,
      deleteSubItem,
      updateList,
      previewImages,
      dateChange,
      backToList,
      submit,
    };
  },
});
