
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { TaggingItem } from "@/core/directive/interface/common";
import {
  CommonButtonType,
  CommonFormType,
  CommonHtmlType,
} from "@/core/directive/type/common";
import { commonChangeDefaultDate } from "@/core/directive/function/common";
import {
  orderRequestItemData,
  orderRequestItemSubData,
  OrderRequestItemSubTable,
  OrderRequestItemTable,
} from "@/core/directive/interface/orderRequest";
import { OrderRequestProgress } from "@/core/directive/type/orderRequest";

export default defineComponent({
  name: "add-exception-handlings-modal",
  components: {},
  props: {
    serviceProvider: {
      type: Array as () => TaggingItem[],
      default: () => {
        return [];
      },
    },
    itemId: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    itemData: {
      type: Object as () => OrderRequestItemTable,
      default: () => {
        return Object.assign({}, orderRequestItemData);
      },
    },
    itemSubIndex: {
      type: Number,
      default: -1,
    },
    orderProgress: {
      type: Number,
      default: 0,
    },
    editData: {
      type: Object as () => OrderRequestItemSubTable,
      default: () => {
        return Object.assign({}, orderRequestItemSubData);
      },
    },
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const formRef = ref<CommonFormType>(null);
    const loading = ref<boolean>(false);
    const orderRequestItemRef = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonButtonType>(null);

    const { showValidateErrorMsg, showFormSubmitSuccessMsg } = mixin();

    const formData = ref(Object.assign({}, orderRequestItemSubData));

    const rules = ref({
      // merchant_order_number: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      confirmation_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      // merchant_invoice_number: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // shipping_date: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // service_provider: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // tracking_no: [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      shipping_qty: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({});

    watch(
      () => props.itemId,
      (newValue) => {
        if (newValue !== 0) {
          formData.value = Object.assign({}, props.editData);
        }
      }
    );

    const subQtyMax = computed(() => {
      let subTotal = 0;
      props.itemData.relation_shipment.map((sub, subIndex) => {
        if (subIndex != props.itemSubIndex) {
          subTotal += sub.shipping_qty;
        }
      });
      return props.itemData.request_qty - subTotal;
    });

    const handleDiscard = () => {
      // resetForm();
      hideModal(orderRequestItemRef.value);
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          showFormSubmitSuccessMsg(() => {
            // resetForm();
            hideModal(orderRequestItemRef.value);
            emit("update-list", formData.value);
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const modalShowListener = (modalEl: HTMLElement | null, callback): void => {
      if (!modalEl) {
        return;
      }
      modalEl.addEventListener("show.bs.modal", callback);
    };

    onMounted(() => {
      modalShowListener(orderRequestItemRef.value, () => {
        // formData.value = Object.assign({}, props.editData);
      });
      modalHideListener(orderRequestItemRef.value, () => {
        emit("reset-form");
      });
    });

    return {
      t,
      OrderRequestProgress,
      props,
      commonChangeDefaultDate,
      loading,
      formData,
      orderRequestItemRef,
      submitButton,
      rules,
      formRef,
      options,
      subQtyMax,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
